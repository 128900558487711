import { H2, H5, Grid, Link, Block } from '#ui';
import ResponsiveMedia from '@components/ResponsiveMedia';
import RichText from '@components/RichText';
import AppButton from '@components/AppButton';
import { useStyles } from './copyimage.styles';

import type { FC } from 'react';
import type { CopyImageSectionProps as Props } from './types';

const CopyImageSection: FC<Props> = ({
  order,
  variant, 
  heading,
  superheading,
  responsiveMedia,
  copy,
  ctaButtonCollection,
  primaryCtaColor,
  secondaryCtaButtonCollection,
  backgroundColor,
  accentColor,
  lineLength,
  listStyle,
  checkBulletStyle
}) => {
  const Styles = useStyles({ order, variant, backgroundColor, accentColor, lineLength });
  const noImg = variant === 'No Image';
  const listTheme = listStyle ? 'Legal' : 'Default';
  const ctas = ctaButtonCollection?.items || [];
  const secondaryCtas = secondaryCtaButtonCollection?.items || [];

  return (
    <Grid
      as="section"
      noPadding
      subgrid
      sx={Styles.ROOT} 
      aria-label={heading || undefined}
    >
      <Block col={[null, '1/-1']} sx={Styles.WRAPPER}>
        {(responsiveMedia?.desktopAsset?.url && !noImg) &&
          <ResponsiveMedia
            sx={Styles.RESPONSIVE_MEDIA}
            sizes={[100, 50]}
            {...responsiveMedia}
          />
        }
        <div sx={Styles.CONTENT}>
          {superheading?.length && <H5 as='small'>{superheading}</H5>}
          {heading && <H2 sx={Styles.HEADING}>{heading}</H2>}
          {copy?.json &&
            <div sx={Styles.RICH_TEXT}>
              <RichText
                {...copy}
                bkg={backgroundColor}
                theme={listTheme}
                checkBulletStyle={!!checkBulletStyle}
              />
            </div>
          } 
          {(!!ctas?.length || !!secondaryCtas?.length) && (
            <div sx={Styles.CTA_CONTAINER}>
              {!!ctas?.length &&
                ctas.map((item, key) => (
                  <AppButton
                    {...item}
                    key={key}
                    componentStyle={Styles.CTA_BUTTON}
                    contrast={backgroundColor}
                    color={primaryCtaColor}
                  >
                    {item.ctaCopy}
                  </AppButton>
                ))}
              <div sx={Styles.SECONDARY_CTA_CONTAINER}>
                {!!secondaryCtas?.length &&
                  secondaryCtas.map((item, key) => (
                    <Link
                      key={key}
                      href={item.destination || undefined}
                      sx={Styles.SECONDARY_CTA_LINKS}
                      withIcon
                      contrast={backgroundColor}
                      openInNewTab={item.openInNewTab}
                    >
                      {item.ctaCopy}
                    </Link>
                  ))}
              </div>
            </div>
          )}
        </div>
      </Block>
    </Grid>
  );
};

export default CopyImageSection;
