import { getSpacing } from '@lib/utils';
import { ColorHex, ColorName } from '#constants';
import { useContrast } from '#hooks';

import type { CopyImageStyles as SS } from './types';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = ({
  order,
  variant,
  backgroundColor: bkg,
  accentColor,
  lineLength,
}: SS): typeof Styles => {
  const { text } = useContrast(bkg);
  const backgroundColor = ColorHex[bkg || 'White'];
  const _accentColor = ColorHex[accentColor || 'White'];
  const noImg = variant === 'No Image';
  const copyWidth = noImg && lineLength === 'Short' ? '75%'
    : noImg && lineLength !== 'Short' ? '100%' : '50%';
  const half = variant?.includes('1/2');
  const third = variant?.includes('1/3');
  const imgRight = variant?.includes('Right');
  const colorfulImg = variant === 'Colorful Image';
  const featured = variant?.includes('Featured');
  const smallHeading = half || colorfulImg;
  const halfImg = half || colorfulImg || featured;

  const standardBkg: boolean = [
    ColorName.DEFAULT,
    ColorName.WHITE,
    ColorName.BLACK,
    ColorName.PRIMARY,
  ].includes(bkg);

  const Styles = {
    ROOT: {
      marginBlockStart: getSpacing(order),
      backgroundColor,
      color: text,
      maxInlineSize: noImg && [null, '52rem', '64rem'],
      marginInline: 'auto',
      borderBottom: colorfulImg && `25px solid ${_accentColor}`,
      borderBlockEnd: colorfulImg && `25px solid ${_accentColor}`,
      borderBottomRightRadius: featured && ['125px', null, '300px'],
      borderEndEndRadius: featured && ['125px', null, '300px'],
      overflow: 'hidden',
    } as TSO,

    WRAPPER: {
      display: 'flex',
      flexDirection: imgRight ? ['column', 'row-reverse'] : ['column', 'row'],
      alignItems: 'center',
      justifyContent: 'center',
    } as TSO,

    RESPONSIVE_MEDIA: {
      inlineSize: halfImg ? ['100%', '50%'] 
        : third ? ['100%', '33%'] : '100%',
      marginInlineEnd: featured && imgRight && ['0px', null, 6]
    } as TSO,

    RICH_TEXT: {
      ol: {
        marginInlineStart: 4,
        paddingBlockStart: 2,
        paddingBlockEnd: 2,
        '& ol': {
          listStyleType: 'lower-alpha',

          '& ol': {
            listStyleType: 'lower-roman',
          },
        },
      },
      ul: {
        paddingBlockStart: 2,
        paddingBlockEnd: 2,
        li: {
          backgroundPosition: '0 .45rem',
        },
      },
    } as TSO,

    HEADING: {
      marginBlockStart: [4, '0px'],
      fontSize: smallHeading && [4, 6],
      fontWeight: !standardBkg ? ['heading', 'extraBold'] : ['heading', 'regular'],
      'small + &': { marginBlockStart: 1 },
    } as TSO,

    CONTENT: {
      inlineSize: third ? ['100%', '67%'] : ['100%', copyWidth],
      paddingBlockStart: [5, 6],
      paddingBlockEnd: 6,
      paddingInlineEnd: noImg ? 4 : [3, null, 6],
      paddingInlineStart: noImg ? 4 : [3, null,  6],
      p: colorfulImg && {
        fontSize: [3, 5],
        fontWeight: ['bold', 'normal'],
      },
    } as TSO,

    CTA_CONTAINER: {
      display: 'flex',
      inlineSize: '100%',
      flexFlow: ['column wrap', null, 'row wrap'],
      alignItems: 'baseline',
      justifyContent: 'flex-start',
    } as TSO,

    CTA_BUTTON: {
      marginBlockStart: 5,
      marginInlineEnd: [null, null, 5],
    } as TSO,

    SECONDARY_CTA_CONTAINER: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBlockStart: 5,
    } as TSO,

    SECONDARY_CTA_LINKS: {
      '&:not(:first-of-type)': {
        marginBlockStart: 1,
      },
    } as TSO,
  };

  return Styles;
};
